import React, {Suspense} from 'react'
import useStyleSuspense from './styles/useStylesSuspense';
import {createUseStyles} from 'react-jss';
import { Box } from '@mui/material';
import { CircularProgress } from "@mui/material";
import Typography from '@mui/material/Typography';

const LoadingMaskSuspense = props => {

  const {children, ...next} = props;
  const useStyles = createUseStyles(useStyleSuspense(props));
  const classes = useStyles();

  return (!classes ? '' :
    <Suspense fallback={
      <Box
        className={classes.suspenseWait}
      >
          <CircularProgress color={props.color} />
          <Typography variant="caption" className={classes.suspenseTitle}>
              {props.message}
          </Typography>
      </Box>}
    >
      {children}
    </Suspense>
  )
}

LoadingMaskSuspense.defaultProps = {
  message: 'Cargando, espere un momento..'
}

export default LoadingMaskSuspense;
