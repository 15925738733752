import React from 'react';

const useStylesSuspense = (props) => {

    const getStyleConfig = () => {
        return {
            suspenseTitle: {
                paddingRight: 10
                ,paddingLeft: 10
            }
            ,suspenseWait: {
                minHeight: '100vh'
                ,width: '100%'
                ,minWidth: '100%'
                ,display: 'flex'
                ,flexDirection: 'row'
                ,alignItems: 'center'
                ,justifyContent: 'center'
            }
            ,suspenseApply: props.sx || {}
        }
    }

    const [style, setStyle] = React.useState(getStyleConfig(props));

    React.useEffect(()=>{
        setStyle(getStyleConfig(props));
    },[props.sx]);

    return style;

}

export default useStylesSuspense;
